import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "./../scss/404.scss";

const NotFoundPage = () => (
  <Layout>
    <SEO title="PAGE NOT FOUND (404)" />
    <div className="error">
      <h1>PAGE NOT FOUND</h1>
      <p>You just requested a page that doesn&#39;t exist... 🙃</p>
    </div>
  </Layout>
);

export default NotFoundPage;
